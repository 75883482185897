//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";

thead button.ascending::after {
    content: "↓";
    display: inline-block;
    margin-left: 1em;
}

thead button.descending::after {
    content: "↑";
    display: inline-block;
    margin-left: 1em;
}

.swal2-container.swal2-center {

    >.swal2-popup {
        padding: 48px 39px;
    }

    .swal2-actions {
        .swal2-styled.swal2-confirm {
            // background-color: #F5F8FA;
            // color: #7E8299;
            background-color: #009EF7;
            color: #fff;


            &:focus {
                box-shadow: none;
            }
        }

        .swal2-styled.swal2-cancel {
            background-color: transparent;
            color: rgba(63, 66, 84, 0.75);
        }
    }

    h2.swal2-title {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        font-size: 13px;
    }

    .swal2-input {
        border: none;
        outline: none;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        background: #F5F8FA;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }
}