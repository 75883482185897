.subdescription_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn_view_automation {
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
    }
}

.attendance_wrapper {
    .btn.btn-light-primary {
        &:focus:not(.btn-active) {
            background-color: #F1FAFF !important;
            color: #009EF7 !important;

            i {
                color: #009EF7 !important;
            }
        }

        &:hover:not(.btn-active) {
            background-color: #009EF7 !important;
            color: #F1FAFF !important;

            i {
                color: #F1FAFF !important;
            }
        }
    }
    .step_wrapper_0 {
        .play_wrapper {
            border: 1px solid #ededed;
            border-radius: 5px;
            padding: 180px 0;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            .btn_play {
                cursor: pointer;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    
        .video_player {
            width: 100%;
        }

        .video_description {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            color: #7E8299;
            margin-top: 12px;
        }
    }

    .step_wrapper_1 {
        padding: 120px 0;
        cursor: pointer;
    }

    .step_wrapper_2 {
    }

    .step_wrapper_3 {
        padding-top: 20px;

        > h4 {
            color: #3F4254;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        .check_wrapper {
            max-height: 285px;
            overflow-y: scroll;
        }

        .form-check {
            .form-check-label {
                color: #3F4254;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 300;
            }
        }

        .action_line {
            .action_name {
                color: #3F4254;
                font-family: Poppins;
                font-size: 13px;
                font-weight: 300;
            }
            .action_buttons {
                .btn_remove {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .btn_add {
            padding: 0;

            &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
                padding: 0;
            }
        }
    }

    .step_wrapper_4 {
        .trigger_item_wrapper {

            .trigger_item {
                h4 {
                    color: #3F4254;
                    font-family: Poppins;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }

        > p {
            color: #A1A5B7;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 300;
        }
    }
}

.auto_all_wrapper {
    .back_button {
        font-family: Poppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        cursor: pointer;
        margin-bottom: 60px;


        &:hover {
            opacity: 0.7;
        }
    }

    .card_container {
        max-height: 387px;
        overflow-y: scroll;

        .add_new_card {
            padding: 48px 30px;
            border: 1px solid #E2E6EC;
            max-width: 285px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 156px;
            width: 100%;
            cursor: pointer;

            span {
                font-family: Poppins;
                font-weight: 300;
                font-size: 15px;
                color: rgba(24, 28, 50, 0.34);
            }
        }
    }

    .btn_add_wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 74px;
    }
}

.auto1_wrapper, .auto4_wrapper {
    position: relative;

    select.auto_select {
        padding-right: 25px;
        border: none;
        border-bottom: 2px solid #009EF7;
        background: url("../../../../../images/icons/auto_arrow_dropdown_circle.svg") no-repeat center right !important;
        background-color: transparent !important;
        appearance: none;
    }

    img {
        max-width: unset;
    }

    .back_button {
        font-family: Poppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .angle_down_icon {
        margin-left: 10px;
        margin-bottom: 50px;
    }

    .line_wrapper {
        padding-left: 10px;
        display: flex;
        justify-content: space-between;

        .actions_wrapper {
            margin-left: 80px;
            display: flex;
            align-items: center;
            height: 33px;

            .btn_plus {
                margin-right: 14px;
                cursor: pointer;
                &:hover {
                    opacity: 0.5;
                }
            }
            .btn_trash {
                cursor: pointer;
                &:hover {
                    opacity: 0.5;
                }
            }
        }

        div:first-child {
            font-family: Poppins;
            font-weight: 400;
            font-size: 14px;
            line-height:33px;
            margin-bottom: 40px;

            input.text_input_long {
                width: 300px;
            }
    
            input {
                border-top-width: 0px;
                border-left-width: 0px;
                border-right-width: 0px;
                border-bottom: 2px solid #009EF7;
                width: 86px;
                padding-bottom: 0;
    
                &:active, &:focus, &:focus-visible {
                    border-top-width: 0px;
                    border-left-width: 0px;
                    border-right-width: 0px;
                    border-bottom: 2px solid #009EF7;
                    outline: none;
                }
            }
        }
    }


    button {
        margin-top: 70px;
    }

    .actions_wrapper {
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 767.98px) {
    .attendance_wrapper {
        .step_wrapper_2 {
            flex-direction: column;

            .attendance_or {
                padding-bottom: 20px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .auto1_wrapper, .auto4_wrapper {
        .back_button {
            left: 0;
        }
    }
}

@media (max-width: 539.98px) {
    .auto1_wrapper, .auto4_wrapper {
        .line_wrapper {
            .actions_wrapper {
                margin-left: 20px;
            }
        }
    }
}